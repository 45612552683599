import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'
import {ElMessageBox} from "element-plus";

// import vueSwiper from 'vue-awesome-swiper'

createApp(App).use(ElementPlus, { locale }).use(router).use(VueAxios,axios).use(ElMessageBox).use(store).mount('#app')

