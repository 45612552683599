import {createStore} from 'vuex'

const store=createStore({
    state(){
        return {
            show:false,
            option:1
        }
    }
})

export default store