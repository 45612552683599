<template>
  <div id="app">
    <!-- <router-link to="/"></router-link> -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
  body{
   margin: 0; 
  }
</style>
